import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import MyMap from "../components/geo/MyMap"
import TwFrame from "../components/Style/TwFrame"

import { FormattedMessage, injectIntl} from "gatsby-plugin-intl"





//export default ({ data }) => (
  const ContactPage = ({ intl, data }) => (
  <Layout>
    <TwFrame > 
       <SEO lang={intl.locale} 
            title={intl.formatMessage({ id: "KontaktPage_Title",defaultMessage:"Kontakt" })} 
            description={intl.formatMessage({ id: "KontaktPage_Desc",defaultMessage:"Kontaktdaten der Privatpraxis für Dermatologie, Allergologie und Lasertherapie Prof. Dr. med. Peter Michael Kövary, u.a. Anschrift, Telefon, E-mail und Social Media." })}     
        />
    <h1><FormattedMessage id="KontaktPage_Title" defaultMessage="Kontakt" /></h1>
    
  <div >
    <div  >
      <div id="Kontakt" >
            <h2><span style={{color: '#CDCDCD'}}>
            <FormattedMessage id="Adresse" defaultMessage="Adresse" />:              
              </span></h2>
            <p>                   
              <emsp />Privatpraxis für Haut-, Geschlechtskrankheiten<br /> und Allergologie<br />
              <emsp />Prof. Dr. med. Peter Michael Kövary<br />
              <emsp />Norderoog 1<br />
              <emsp />28259 Bremen
            </p>
        </div>
        <div id="Oeffnungszeiten" >
          <p>
          </p><h2><span style={{color: '#CDCDCD'}}>
          <FormattedMessage id="Oeffnungszeiten" defaultMessage="Öffnungszeiten" />:
          </span></h2>
          <emsp /><span>
          <FormattedMessage id="MoBisFr" defaultMessage="Mo–Fr"/>:
            </span> <span>
            <FormattedMessage id="ZeitenVormittag" defaultMessage="9:00–13:00 Uhr"/>
              </span><br />
          <emsp />
          <p />
          <h2><span style={{color: '#CDCDCD'}}>Kontakt:</span></h2>
          <p>
            <emsp />
            <FormattedMessage id="Telefon" defaultMessage="Telefon"/>: 
            <a href="tel:0421- 520 79 575">0421- 520 79 575 </a><br />
            <emsp />
            <FormattedMessage id="Telefax" defaultMessage="Telefax"/>: 
            <a >0421- 520 79 576 </a><br />
            <emsp />

            <FormattedMessage id="EMail" defaultMessage="E-Mail"/>: 
            <a href="mailto:info@allab.de"> info@allab.de</a><br />
            <emsp />
            <FormattedMessage id="SocialMedia" defaultMessage="Social Media"/>: 
            <a href="http:\\www.facebook.de\HautarztpraxisBremen" target="_blank" rel="noopener noreferrer"> <i className="fab fa-facebook-f" /></a><br />
          </p>
          </div>
        </div>
  </div>

<div id="Anreise">
<h2><span style={{color: '#CDCDCD'}}>
  <FormattedMessage id="Anreise" defaultMessage="Anreise"/>:   
  </span></h2>
            <p>
            <FormattedMessage id="AnreiseBeschreibung" defaultMessage="
Ab Hauptbahnhof fahren Sie mit der Linie 1 oder 8 von Domsheide in Richtung Huchting (Roland-Center). 
Dort steigen Sie um und nehmen den Bus 58 in Richtung Mittelshuchting bis zur Haltestelle Carl-Hurzig-Straße. 
Gehen Sie ca. 150 Meter zurück. Die Strasse Norderoog 1 befindet sich gegenüber der Rückseite der Aral-Tankstelle.
Unsere Praxis finden Sie im 1. Stock, rechter Hand."/>  
  </p>
</div>
    <MyMap />
    </TwFrame > 
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default injectIntl(ContactPage)