import React,{useState, useEffect } from "react"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"  


/* Alternativ: https://github.com/masotime/react-leaflet-universal */
import "leaflet/dist/leaflet.css"
import { Link } from 'gatsby'
import TwFrame from "../Style/TwFrame";

export default () => {  
  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);
  const [navbarOpen, setNavbarOpen] = useState(false)
  const position = [53.05357, 8.7424]
  const message = "Privatpraxis für Dermatologie, Allergologie und Lasertherapie Prof. Dr. med. Peter Michael Kövary"

  return (
<>
    {navbarOpen ? (
        <Map center={position} zoom={17} style={{zIndex: 0, height: '420px',}} >
        <TileLayer
        attribution='&amp;copy; <a href="https://www.openstreetmap.de/copyright">OpenStreetMap</a> Mitwirkende'
        url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
        <Popup>
            "{message}"
        </Popup>
        </Marker>
        ):
            (

            )
        </Map>
      ) : (
        <p>
          <div class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-green-300 hover:tw-bg-green-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
          <button type="button"   onClick={() => setNavbarOpen(!navbarOpen)}>Karte anzeigen</button>
          </div>
        
        <p> Hinweis:  mit der Freigabe der Anzeige der "OpenStreetmap" Karte und der Links zu externen Seiten von Openstreetmap,                         
          Google und Bing aktzeptieren Sie <Link to="/legal/datenschutz.html#osm">die Datenschutzbestimmungen</Link> dieser Seite.
        </p>          
         </p>  
      )
    }     
    

</>
  );
};
